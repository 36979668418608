import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Russian Twists 3×10/side (20/14)`}</p>
    <p>{`Stability Ball Planks 3×1:00`}</p>
    <p>{`SDHP’s 4×6`}</p>
    <p>{`then,`}</p>
    <p>{`12:00 AMRAP:`}</p>
    <p>{`10-Deadlifts (225/155)`}</p>
    <p>{`10-D Ball Slams (40/20)`}</p>
    <p>{`10-50ft Shuttle Runs`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      